@import "include-media";

.work-wrapper {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 3rem;
    line-height: 50px;
    font-weight: bold;
  }
  p {
    font-size: 13px;
  }
  .grid {
    display: grid;
    width: 500px;
    margin-top: 10px;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    @include media(">=phone", "<lgphone") {
      grid-template-columns: 1fr;
      width: 300px;
    }
    @include media(">=lgphone", "<tablet") {
      grid-template-columns: 1fr;
      width: 500px;
    }

    @include media(">=tablet", "<desktop") {
      grid-template-columns: 1fr;
      width: 800px;
    }
  }
}
