@import "include-media";
.header-wrapper {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 1rem;
    text-align: center;
    @include media(">=phone", "<lgphone") {
      font-size: 14px;
    }
    @include media(">=lgphone", "<tablet") {
      font-size: 16px;
    }
    @include media(">=tablet", "<desktop") {
      font-size: 16px;

    }
  }
  .heading-wrapper {
    h1 {
      font-size: 4rem;
      text-align: center;
      line-height: 10px;
      font-weight: bold;
      @include media(">=phone", "<lgphone") {
        font-size: 2.0rem;
        line-height: 30px;
      }
      @include media(">=lgphone", "<tablet") {
        font-size: 2.5rem;
        line-height: 40px;
      }
      @include media(">=tablet", "<desktop") {
        font-size: 3.5rem;
        line-height: 50px;
      }
    }
  }
  p {
    width: 50%;
    text-align: center;
    overflow: hidden;
    @include media(">=phone", "<lgphone") {
      font-size: 12px;
      width: 100%;
    }
    @include media(">=lgphone", "<tablet") {
      font-size: 12px;
      width: 100%;
    }
    @include media(">=tablet", "<desktop") {
      font-size: 14px;
      width: 100%;
    }
  }
}
