@import "include-media";
.about-section {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  justify-content: space-between;
  @include media(">=phone", "<lgphone") {
    flex-direction: column-reverse;
  }
  @include media(">=lgphone", "<tablet") {
    flex-direction: column-reverse;
  }
  @include media(">=tablet", "<desktop") {
    flex-direction: column-reverse;
    justify-content: space-between;

  }
  .content {
    width: 50%;
    @include media(">=phone", "<lgphone") {
      width: 100%;
      margin-top: 20px;
      text-align: justify;
      text-justify: inter-word;
    }
    @include media(">=lgphone", "<tablet") {
      width: 100%;
      margin-top: 20px;
      text-align: justify;
      text-justify: inter-word;

    }
    @include media(">=tablet", "<desktop") {
      width: 100%;
      text-align: justify;
      text-justify: inter-word;
    }


    h1 {
      font-size: 3rem;
      line-height: 60px;
      font-weight: bold;
      @include media(">=tablet", "<desktop") {
        text-align: center;
      }
    }

    p {
      @include media(">=tablet", "<desktop") {
        text-align: justify;
      }
    }

    .image-wrapper {
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      @include media(">=phone", "<lgphone") {
        width: 100%;
      }
      @include media(">=lgphone", "<tablet") {
        width: 100%;
      }
      @include media(">=tablet", "<desktop") {
        width: 100%;
      }

    }
  }
}
