@import "include-media";
.card {
  width: 100%;
  height: 250px;
  overflow: hidden !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .content {
    font-family: "Open Sans";
    text-align: center;
    padding: 10px;
    opacity: 0;
    transition: all 500ms ease-in-out;
    transform: translate(0, 20px);
    cursor: pointer;
    h1 {
      font-size: 14px;
      color: white;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      color: white;
      margin-bottom: 30px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .btn {
      background-color: black;
      color: white;
      padding: 8px;
      text-decoration: none;
    }

    .btn:hover {
      background-color: white;
      color: black;

    }

    &:hover {
      opacity: 2;
      transform: translate(0, 0px);
    }
  }
}
